// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-template-index-js": () => import("./../../../src/templates/blog-template/index.js" /* webpackChunkName: "component---src-templates-blog-template-index-js" */),
  "component---src-templates-locations-template-index-js": () => import("./../../../src/templates/locations-template/index.js" /* webpackChunkName: "component---src-templates-locations-template-index-js" */),
  "component---src-templates-solution-template-index-js": () => import("./../../../src/templates/solution-template/index.js" /* webpackChunkName: "component---src-templates-solution-template-index-js" */)
}

